import React from 'react';
import classes from './HelpCard.module.css';

export default function HelpCard(props) {

	switch (props.title) {
		case 'SLH':
			return (
				<div>
					Send an{' '}
					<a href='mailto:rgay@ept911.com?subject=SLH Admission List' className={classes.a}>
						email
					</a>{' '}
					for assistance with this list.
				</div>
			);
		case 'SNGH':
			return (
				<div>
					Send an{' '}
					<a href='mailto:rgay@ept911.com?subject=SNGH Admission List' className={classes.a}>
						email
					</a>{' '}
					for assistance with this list.
				</div>
			);
		case 'SOH/SBLH':
			return (
				<div>
					Send an{' '}
					<a href='mailto:rgay@ept911.com?subject=SOH/SBLH Admission List' className={classes.a}>
						email
					</a>{' '}
					for assistance with this list.
				</div>
			);
		case 'SPAH':
			return (
				<div>
					Send an{' '}
					<a href='mailto:smccann@ept911.com?subject=SPAH Admission List' className={classes.a}>
						email
					</a>{' '}
					for assistance with this list.
				</div>
			);
		case 'SVBGH/SI':
			return (
				<div>
					Send an{' '}
					<a href='mailto:rgay@ept911.com?subject=SVBGH/SI Admission List' className={classes.a}>
						email
					</a>{' '}
					for assistance with this list.
				</div>
			);
		default:
			return <div></div>;
	}
}
