import React from 'react';
import Card from '@mui/material/Card';

function PageCards(props) {
	return (
		<Card
			variant='outlined'
			sx={{
				borderRadius: '16px',
				padding: '10px',
				marginTop: '1rem',
				marginBottom: '1rem',
				boxShadow: '0 0px 3px rgba(0, 0, 0, 0.3)',
			}}
		>
			{props.children}
		</Card>
	);
}

export default PageCards;
