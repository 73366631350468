import React, { useState, useEffect, useRef, useContext } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import PageCards from '../components/ui/PageCards';
import TextEditor from '../components/textEditor/TextEditor';
import AlertContext from '../components/ui/AlertContext';

function Admin_Edit_Pages(props) {
	const alertCtx = useContext(AlertContext);
	const [main, setMain] = useState('');
	const [valid, setValid] = useState(false);
	const [facility, setFacility] = useState('');
	const editorContentRef = useRef(''); // To store the latest editor content

	// Handle dropdown facility change
	const handleFacilityChange = (event) => {
		setFacility(event.target.value);
	};

	// Fetch the page info when facility changes
	useEffect(() => {
		const getFacilityPageInfo = async () => {
			setMain(''); // Clear the main content when facility changes
			const docRef = doc(props.db, 'settings', facility);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				setMain(docSnap.data().pageInfo);
			} else {
				setMain('');
			}
		};

		if (facility !== '') getFacilityPageInfo();
	}, [facility, props.db]);

	// Handle the text changing from the editor
	const mainChangeHandler = (editorObj) => {
		// Store the latest content in a ref
		editorContentRef.current = editorObj.text;
		setValid(editorObj.valid);
		// Do not update 'main' during typing to prevent cursor reset
	};

	// Handle the save button click
	const handleSubmit = async () => {
		const docRef = doc(props.db, 'settings', facility);
		const contentToSave = editorContentRef.current; // Get content from ref

		try {
			await updateDoc(docRef, { pageInfo: contentToSave });
			setFacility('');
			setMain('');
			alertCtx.setMessage('The page content was updated successfully!');
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Success');
			alertCtx.setTimer(5000);
			alertCtx.setActive(true);
		} catch (err) {
			alertCtx.setMessage(
				`There was an error. Please refresh the page and try again. If you experience this error again, notify IT. - ${err}`
			);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
			alertCtx.setActive(true);
		}
	};

	return (
		<Grid container spacing={1} m={2}>
			<Grid xs={12}>
				<PageCards>
					<h1>ADMIN</h1>
					<h3>Select a facility to edit the page content.</h3>
				</PageCards>
			</Grid>

			<Grid xs={12}>
				<PageCards>
					<Box display='flex' alignItems='center' justifyContent='center' sx={{ maxWidth: 150, margin: '1rem auto' }}>
						<FormControl fullWidth>
							<InputLabel id='facility-label'>Facility</InputLabel>
							<Select
								labelId='facility-label'
								id='facility-select'
								value={facility}
								label='Facility'
								onChange={handleFacilityChange}
							>
								<MenuItem value={'SBLH'}>SBLH</MenuItem>
								<MenuItem value={'SI'}>SI</MenuItem>
								<MenuItem value={'SLH'}>SLH</MenuItem>
								<MenuItem value={'SNGH'}>SNGH</MenuItem>
								<MenuItem value={'SOH'}>SOH</MenuItem>
								<MenuItem value={'SPAH'}>SPAH</MenuItem>
								<MenuItem value={'SVBGH'}>SVBGH</MenuItem>
							</Select>
						</FormControl>
					</Box>

					<Box>
						<TextEditor key={main} initialContent={main} onChange={mainChangeHandler} disabled={false} />
					</Box>

					<Stack direction='row' spacing={2} justifyContent='center' mb={12} mt={3}>
						<Button
							disabled={!valid || facility === ''}
							variant='contained'
							startIcon={<SaveRoundedIcon />}
							color='primary'
							onClick={handleSubmit}
						>
							Save
						</Button>
					</Stack>
				</PageCards>
			</Grid>
		</Grid>
	);
}

export default Admin_Edit_Pages;
