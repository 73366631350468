import { Route, Routes, Navigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Home from './pages/Home.js';
import SBLH from './pages/SBLH.js';
import SI from './pages/SI.js';
import SLH from './pages/SLH.js';
import SNGH from './pages/SNGH.js';
import SOH from './pages/SOH.js';
import SPAH from './pages/SPAH.js';
import SVBGH from './pages/SVBGH.js';
import Admin_Edit_Cards from './pages/Admin_Edit_Cards.js';
import Admin_Edit_Pages from './pages/Admin_Edit_Pages.js';
import Admin_Edit_Categories from './pages/Admin_Edit_Categories.js';
import Layout from './components/layout/Layout';
import CustomClaimsContext from './components/auth/CustomClaimsContext.js';
import LoadingSpinner from './components/ui/LoadingSpinner.js';
import firebaseConfigSettings from './firebaseConfig.js';

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = firebaseConfigSettings;
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export const db = getFirestore(app);

/* Material UI Theme */
const theme = createTheme({
	palette: {
		type: 'light',
		primary: {
			main: '#1F2253',
		},
		secondary: {
			main: '#5b7fb4',
		},
		info: {
			main: '#2196f3',
		},
		success: {
			main: '#4caf50',
		},
		error: {
			main: '#de433e',
		},
		cancel: {
			main: '#b0bec5',
		},
		deleteButton: {
			light: '#ef5350',
			main: '#d32f2f',
			dark: '#b71c1c',
			contrastText: '#fff',
		},
		saveButton: {
			light: '#9fa8da',
			main: '#3949ab',
			dark: '#1a237e',
			contrastText: '#fff',
		},
		otherButton: {
			light: '#9575cd',
			main: '#5e35b1',
			dark: '#311b92',
			contrastText: '#fff',
		},
		lessThan90Percent: {
			light: '#db5858',
			dark: '#D84545',
			main: '#C12929',
			contrastText: '#FFF',
		},
		within10Percent: {
			light: '#fbcc57',
			main: '#fbc02d',
			dark: '#af861f',
			contrastText: '#000',
		},
		greaterThan100Percent: {
			light: '#6fbf73',
			main: '#7BB662', //'#49B654',
			dark: '#3D8C40',
			contrastText: '#000',
		},
	},
});

function App() {
	logEvent(analytics, 'notification_received');
	const claimsCtx = useContext(CustomClaimsContext);
	const [claims, setClaims] = useState(null);

	useEffect(() => {
		// console.log('claimsCtx.claimsLoaded', claimsCtx.claimsLoaded);
		if (claimsCtx.claimsLoaded) {
			// console.log('claims', claimsCtx.claims);
			setClaims(claimsCtx.claims);
		}
	}, [claimsCtx.claimsLoaded, claimsCtx]);

	if (!claims) return <LoadingSpinner />;

	return (
		<ThemeProvider theme={theme}>
			<Layout>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/sblh' element={<SBLH db={db} />} />
					<Route path='/si' element={<SI db={db} />} />
					<Route path='/slh' element={<SLH db={db} />} />
					<Route path='/sngh' element={<SNGH db={db} />} />
					<Route path='/soh' element={<SOH db={db} />} />
					<Route path='/spah' element={<SPAH db={db} />} />
					<Route path='/svbgh' element={<SVBGH db={db} />} />
					<Route path='/admin_edit_cards' element={claims?.admin ? <Admin_Edit_Cards /> : <Navigate to='/' />} />
					<Route
						path='/admin_edit_pages'
						element={claims?.admin ? <Admin_Edit_Pages db={db} /> : <Navigate to='/' />}
					/>
					<Route path='/admin_edit_categories' element={claims?.admin ? <Admin_Edit_Categories /> : <Navigate to ='/' />} />
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Layout>
		</ThemeProvider>
	);
}

export default App;