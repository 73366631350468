import React, { useContext, useState, useEffect } from 'react';

import { GoogleAuthProvider, getRedirectResult, onAuthStateChanged, signInWithRedirect } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { Button } from '@mui/material';

import AuthContext from './authContext';
import AlertContext from '../ui/AlertContext';
import LoadingSpinner from '../ui/LoadingSpinner';
import apiKeySettings from '../../apiKey';
import { auth } from '../../App';

export const SCOPES = [
	'https://www.googleapis.com/auth/directory.readonly',
	'https://www.googleapis.com/auth/admin.directory.user.readonly',
	'https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
	'https://www.googleapis.com/auth/admin.directory.group.readonly',
];

export const API_KEY = apiKeySettings.API_KEY;
export const CLIENT_ID = apiKeySettings.CLIENT_ID;

const provider = new GoogleAuthProvider();
for (const scope of SCOPES) provider.addScope(scope);
provider.setCustomParameters({
	hd: 'ept911.com',
});

export default function SignIn() {
	const authCtx = useContext(AuthContext);
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleGoogleSignIn = async () => {
		setIsLoading(true);
		await signInWithRedirect(auth, provider);
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				setIsLoading(true);
				const result = await getRedirectResult(auth);

				if (result) {
					const credential = GoogleAuthProvider.credentialFromResult(result);
					const token = credential.accessToken;

					const user = result.user;
					const expirationTime = user.stsTokenManager.expirationTime;
					const photo = user?.providerData?.[0]?.photoURL || null;
					const userName = user.displayName;
					const email = user.email;
					const uid = user.uid;

					const functions = getFunctions();
					const verifyEPTEmailWithCustomClaimsV2 = httpsCallable(functions, 'verifyEPTEmailWithCustomClaimsV2');

					verifyEPTEmailWithCustomClaimsV2({})
						.then((result) => {
							const verified = result.data.verified;

							if (verified) {
								authCtx.login(token, expirationTime, photo, userName, email, uid);
							} else {
								authCtx.logout();
								alertCtx.setSeverity('error');
								alertCtx.setMessage(
									'An EPT Email account must be used to login. Retry logging in using an EPT Email account.'
								);
								alertCtx.setActive(true);
								alertCtx.setTimer(10000);
							}
						})
						.catch((err) => {
							console.error(err);
						})
						.finally(() => {
							setIsLoading(false);
						});
				} else {
					setTimeout(() => {
						setIsLoading(false);
					}, 15000);
				}
			}
		});

		return () => unsubscribe();
	}, [authCtx, alertCtx]);

	if (isLoading) return <LoadingSpinner />;

	return (
		<Button variant='contained' onClick={handleGoogleSignIn}>
			Log In
		</Button>
	);
}
