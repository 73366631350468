import React from 'react';
import MainNavigation from './MainNavigation.js';
import classes from './Layout.module.css';

function Layout(props) {
	return (
		<div id={props.pageWrapId}>
			<MainNavigation />
			<main className={classes.main}>{props.children}</main>
		</div>
	);
}

export default Layout;
