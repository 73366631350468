import React, { useState, useContext, useEffect, useRef } from 'react';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import parse from 'html-react-parser';
import TextEditor from '../textEditor/TextEditor';

import FacilityInfoSubmission from './FacilityInfoSubmission';
import FileUploads from './FileUploads';
import AuthContext from '../auth/authContext';

import classes from './FacilityInfoForm.module.css';
import { Box, Checkbox, FormControl, ListItemText, MenuItem, Select } from '@mui/material';

export default function FacilityInfoForm(props) {
	const authCtx = useContext(AuthContext);
	const token = authCtx.token;
	const author = authCtx.userName;
	const initialDate = new Date();
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [initialLoaded, setInitialLoaded] = useState(false);
	const [submissionError, setSubmissionError] = useState(null);
	const [submissionSuccess, setSubmissionSuccess] = useState(null);
	const [loading, setLoading] = useState(false);
	const defaultFacilities = ['SBLH', 'SI', 'SLH', 'SNGH', 'SOH', 'SPAH', 'SVBGH', 'TEST'];
	const defaultCategories = props.categoryList;
	// Initialize topic object
	let topic = {
		Title: '',
		Summary: '',
		Main: '',
		Files: [],
		Facilities: [],
		Categories: [],
		Date: initialDate,
		PostedBy: null,
	};

	// Validation state initializations
	let titleValidInitial = false;
	let titleTouchedInitial = false;
	let summaryValidInitial = false;
	let summaryTouchInitial = false;
	let mainValidInitial = false;
	let mainTouchedInitial = false;
	let facilitiesValidInitial = false;
	let facilitiesTouchedInitial = false;
	let formValidInitial = false;
	let formTouchedInitial = false;

	// If editing an entry, populate initial states
	if (props?.editEntry?.Title) {
		topic = {
			Title: props.editEntry.Title,
			Summary: props.editEntry.Summary,
			Main: props.editEntry.Main,
			Files: props.editEntry.Files,
			Facilities: props.editEntry.Facilities,
			Categories: props.editEntry.Categories || [],
			Date:
				props.editEntry.Date.seconds && props.editEntry.Date.nanoseconds
					? new Date(props.editEntry.Date.seconds * 1000 + props.editEntry.Date.nanoseconds / 1000000)
					: props.editEntry.Date.toDate(),
			PostedBy: props.editEntry.PostedBy,
			UpdatedBy: props.editEntry.UpdatedBy,
			UpdatedDate:
				props.editEntry?.UpdatedDate?.seconds && props.editEntry?.UpdatedDate?.nanoseconds
					? new Date(props.editEntry.UpdatedDate.seconds * 1000 + props.editEntry.UpdatedDate.nanoseconds / 1000000)
					: props.editEntry?.UpdatedDate?.toDate() || null,
		};

		titleValidInitial = true;
		titleTouchedInitial = true;
		summaryValidInitial = true;
		summaryTouchInitial = true;
		mainValidInitial = true;
		mainTouchedInitial = true;
		facilitiesValidInitial = true;
		facilitiesTouchedInitial = true;
		formValidInitial = true;
		formTouchedInitial = true;
	}

	const date = topic.Date;
	const [title, setTitle] = useState(topic.Title);
	const [summary, setSummary] = useState(topic.Summary);
	const [categories, setCategories] = useState(topic.Categories);
	const [facilities, setFacilities] = useState(topic.Facilities);
	const [initialContent, setInitialContent] = useState(topic.Main || '');

	// Use a ref to store editor content without causing re-renders
	const editorContentRef = useRef(topic.Main || '');

	// Update initialContent and editorContentRef when editEntry changes
	useEffect(() => {
		if (props?.editEntry?.Title) {
			setTitle(props.editEntry.Title);
			setSummary(props.editEntry.Summary);
			setFacilities(props.editEntry.Facilities);
			setCategories(props.editEntry.Categories || []);
			setInitialContent(props.editEntry.Main || '');
			editorContentRef.current = props.editEntry.Main || '';

			// Update validation states as needed
			setTitleValid(true);
			setTitleTouched(true);
			setSummaryValid(true);
			setSummaryTouched(true);
			setMainValid(true);
			setMainTouched(true);
			setFacilitiesValid(true);
			setFacilitiesTouched(true);
			setFormValid(true);
			setFormTouched(true);
		} else {
			// Reset form if no editEntry
			resetForm();
		}
	}, [props.editEntry]);

	// Set uploaded files if editing an entry
	useEffect(() => {
		if (topic?.Files && uploadedFiles.length === 0 && !initialLoaded) {
			setUploadedFiles(topic.Files);
		}
	}, []);

	// Mark initial files as loaded
	useEffect(() => {
		if (uploadedFiles.length > 0) {
			setInitialLoaded(true);
		}
	}, [uploadedFiles]);

	// Validation states
	const [titleValid, setTitleValid] = useState(titleValidInitial);
	const [titleTouched, setTitleTouched] = useState(titleTouchedInitial);
	const [titleLengthNotification, setTitleLengthNotification] = useState(false);
	const [summaryValid, setSummaryValid] = useState(summaryValidInitial);
	const [summaryTouched, setSummaryTouched] = useState(summaryTouchInitial);
	const [summaryLengthNotification, setSummaryLengthNotification] = useState(false);
	const [mainValid, setMainValid] = useState(mainValidInitial);
	const [mainTouched, setMainTouched] = useState(mainTouchedInitial);
	const [facilitiesValid, setFacilitiesValid] = useState(facilitiesValidInitial);
	const [facilitiesTouched, setFacilitiesTouched] = useState(facilitiesTouchedInitial);
	const [formValid, setFormValid] = useState(formValidInitial);
	const [formTouched, setFormTouched] = useState(formTouchedInitial);
	const [formSubmittedError, setFormSubmittedError] = useState(false);

	// Handle title changes
	const titleChangeHandler = (event) => {
		setTitle(event.target.value);
		if (event.target.value.trim() !== '') {
			setTitleValid(true);
		} else {
			setTitleValid(false);
		}

		if (event.target.value.length >= 50) {
			setTitleLengthNotification(true);
		} else {
			setTitleLengthNotification(false);
		}
	};

	const titleBlurHandler = (event) => {
		setTitleTouched(true);
		if (event.target.value.trim() === '') {
			setTitleValid(false);
		}
	};

	// Handle summary changes
	const summaryChangeHandler = (event) => {
		setSummary(event.target.value);
		if (event.target.value.trim() !== '') {
			setSummaryValid(true);
		} else {
			setSummaryValid(false);
		}

		if (event.target.value.length >= 100) {
			setSummaryLengthNotification(true);
		} else {
			setSummaryLengthNotification(false);
		}
	};

	const summaryBlurHandler = (event) => {
		setSummaryTouched(true);
		if (event.target.value.trim() === '') {
			setSummaryValid(false);
		}
	};

	// Handle main content changes from the editor
	const mainChangeHandler = (obj) => {
		const parsedText = parse(obj.text).toString();
		editorContentRef.current = obj.text;
		if (parsedText.trim() !== '') {
			setMainValid(true);
		} else {
			setMainValid(false);
		}
		setMainTouched(true);
	};

	// Handle file changes
	const handleFileChanges = (files) => {
		setUploadedFiles(files);
	};

	// Submission success or error handling
	useEffect(() => {
		if (submissionSuccess) {
			window.scrollTo(0, 0);
			props.formSubmissionSuccess();
		} else if (submissionError) {
			setLoading(false);
			setFormSubmittedError(true);
		}
	}, [submissionError, submissionSuccess]);

	// Handle facility checkbox changes
	const facilitiesChangeHandler = (event) => {
		setFacilitiesTouched(true);
		const facility = event.target.id;
		if (facilities.includes(facility)) {
			// Remove facility
			setFacilities(facilities.filter((f) => f !== facility));
		} else {
			// Add facility
			setFacilities([...facilities, facility]);
		}
	};

	// Check if at least one facility is selected
	useEffect(() => {
		if (facilities.length > 0) {
			setFacilitiesValid(true);
		} else {
			setFacilitiesValid(false);
		}
	}, [facilities]);

	// Handle form submission
	const handleSubmitButton = async (event) => {
		event.preventDefault();
		setLoading(true);

		// Upload new files
		const filesUploaded = [];
		if (uploadedFiles.length > 0) {
			for (const file of uploadedFiles) {
				if (!file.url) {
					const storage = getStorage();
					const storageRef = ref(storage, `files/${date}/${file.name}`);
					await uploadBytes(storageRef, file);
					const URL = await getDownloadURL(storageRef);

					const fileData = { name: file.name, reference: `files/${date}/${file.name}`, type: file.type, url: URL };
					filesUploaded.push(fileData);
				} else {
					filesUploaded.push(file);
				}
			}
		}

		// Determine PostedBy and UpdatedBy
		let PostedBy, UpdatedDate, UpdatedBy;
		const isCategoryOnlyChange =
			title === topic.Title &&
			summary === topic.Summary &&
			editorContentRef.current === topic.Main &&
			facilities.length === topic.Facilities.length &&
			facilities.every((f) => topic.Facilities.includes(f)) &&
			uploadedFiles.length === topic.Files.length &&
			uploadedFiles.every(
				(file, index) => file.name === topic.Files[index].name && file.url === topic.Files[index].url
			);

		if (topic.PostedBy === null) {
			PostedBy = author;
			UpdatedBy = null;
			UpdatedDate = null;
		} else {
			PostedBy = topic.PostedBy;
			if (!isCategoryOnlyChange) {
				UpdatedBy = author;
				UpdatedDate = new Date();
			} else {
				UpdatedBy = topic.UpdatedBy || null;
				UpdatedDate = topic.UpdatedDate || null;
			}
		}

		const mainContent = editorContentRef.current;

		const success = await FacilityInfoSubmission(
			date,
			PostedBy,
			UpdatedDate,
			UpdatedBy,
			title,
			summary,
			mainContent,
			filesUploaded,
			facilities,
			categories,
			token,
			props?.editEntry?.id || null
		);

		if (success) {
			setFormSubmittedError(false);
			setSubmissionSuccess(true);
		} else {
			setSubmissionError(true);
		}
	};

	// Check form validity
	useEffect(() => {
		if (titleValid && summaryValid && mainValid && facilitiesValid) {
			setFormValid(true);
			setFormTouched(true);
		} else {
			setFormValid(false);
		}
	}, [titleValid, summaryValid, mainValid, facilitiesValid]);

	const handleCategoryChange = (event) => {
		setCategories(event.target.value);
	};

	// Handle invalid form submission attempt
	const handleFormNotValid = (event) => {
		event.preventDefault();
		setFormTouched(true);
		setTitleTouched(true);
		setSummaryTouched(true);
		setMainTouched(true);
		setFacilitiesTouched(true);
	};

	// Reset the form
	const resetForm = () => {
		setTitle('');
		setSummary('');
		setFacilities([]);
		setCategories([]);
		setInitialContent('');
		editorContentRef.current = '';
		setUploadedFiles([]);
		setInitialLoaded(false);
		setTitleValid(false);
		setTitleTouched(false);
		setSummaryValid(false);
		setSummaryTouched(false);
		setMainValid(false);
		setMainTouched(false);
		setFacilitiesValid(false);
		setFacilitiesTouched(false);
		setFormValid(false);
		setFormTouched(false);
		setFormSubmittedError(false);
		setSubmissionError(null);
		setSubmissionSuccess(null);
		setLoading(false);
		props.resetForm();
	};

	return (
		<form>
			<div className={classes.formControl}>
				{/* Title Section */}
				<>
					<label htmlFor='title'>
						Title
						{!titleValid && titleTouched && <span className={classes.error}>&nbsp;- a Title must be entered.</span>}
						{titleLengthNotification && (
							<span className={classes.error}>&nbsp;- the max of 50 characters for the title has been reached.</span>
						)}
					</label>
					<input
						className={!titleValid && titleTouched ? classes.error : ''}
						id='title'
						type='text'
						maxLength='50'
						value={title}
						onChange={titleChangeHandler}
						onBlur={titleBlurHandler}
					></input>
				</>

				{/* Summary Section */}
				<>
					<label htmlFor='summary'>
						Summary
						{!summaryValid && summaryTouched && (
							<span className={classes.error}>&nbsp;- a Summary must be entered.</span>
						)}
						{summaryLengthNotification && (
							<span className={classes.error}>&nbsp;- the max of 100 characters for the summary has been reached.</span>
						)}
					</label>
					<textarea
						className={!summaryValid && summaryTouched ? classes.error : ''}
						id='summary'
						type='text'
						maxLength='100'
						value={summary}
						rows='2'
						onChange={summaryChangeHandler}
						onBlur={summaryBlurHandler}
					></textarea>
				</>

				{/* Main Content Section */}
				<label htmlFor='main'>
					Main
					{!mainValid && mainTouched && <span className={classes.error}>&nbsp;- Content must be entered.</span>}
				</label>
				<TextEditor
					key={initialContent}
					initialContent={initialContent}
					onChange={mainChangeHandler}
					disabled={loading}
				/>

				{/* File Upload Section */}
				<Box mt={1}>
					<FileUploads date={topic.Date.getTime()} fileChanges={handleFileChanges} initialFiles={uploadedFiles} />
				</Box>

				{/* Facilities Checkbox Section */}
				<>
					<label htmlFor='facilities'>
						Facilities
						{!facilitiesValid && facilitiesTouched && (
							<span className={classes.error}>
								&nbsp;- Please select at least one facility to post this information to.
							</span>
						)}
					</label>
					<div className={!facilitiesValid && facilitiesTouched ? classes.bigBoxError : classes.bigBox}>
						{defaultFacilities.map((defaultFacility, index) => (
							<div className={classes.smallBox} key={index}>
								<label id={defaultFacility}>{defaultFacility}</label>
								<input
									id={defaultFacility}
									type='checkbox'
									checked={facilities.includes(defaultFacility)}
									onChange={facilitiesChangeHandler}
								/>
							</div>
						))}
					</div>
				</>
				<Box mt={2}>
					<label htmlFor='category'>Category</label>
					<FormControl>
						<Select
							multiple
							value={categories}
							onChange={handleCategoryChange}
							renderValue={(selected) => selected.join(', ')}
							sx={{
								'& .MuiSelect-select': {
									padding: '10px',
								},
								minWidth: '300px',
							}}
						>
							{defaultCategories.map((name) => (
								<MenuItem key={name} value={name}>
									<Checkbox checked={categories.indexOf(name) > -1} />
									<ListItemText primary={name} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
				{/* Form Buttons */}
				<div className={classes.center}>
					{loading && (
						<div className={classes.spinner}>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					)}
					{!loading && formValid && formTouched && (
						<button className={classes.buttonFacilityForm} onClick={handleSubmitButton}>
							Submit
						</button>
					)}
					{!loading && !formValid && (
						<button className={classes.buttonFacilityFormInvalidButton} onClick={handleFormNotValid}>
							Submit
						</button>
					)}
					<button className={classes.buttonFacilityFormReset} onClick={resetForm}>
						Reset
					</button>
				</div>

				{formSubmittedError && <div className={classes.submissionError}>Something went wrong, please try again.</div>}
			</div>
		</form>
	);
}
