import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../App';

//deletes data
export default function DeleteFacilityInfo(obj) {
	return new Promise(async (succeeded, failed) => {
		await deleteDoc(doc(db, 'FacilityInfo', obj.id))
			.then(() => {
				succeeded(true);
			})
			.catch((err) => {
				console.error(err);
				failed(false);
			});
	});
}
