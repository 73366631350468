/**
 * PRODUCTION ENVIRONMENT
 */
export default {
	apiKey: 'AIzaSyAVS6ufV1X6XTd_Kk66APRqsR4ptMx1edo',
	authDomain: 'admissionlists.ept911.com', //'admission-lists.firebaseapp.com',
	databaseURL: 'https://admission-lists-default-rtdb.firebaseio.com',
	projectId: 'admission-lists',
	storageBucket: 'admission-lists.appspot.com',
	messagingSenderId: '36050878774',
	appId: '1:36050878774:web:5a16818eea4ed3c52df397',
	measurementId: 'G-5X6G6X3BT4',
};
