import React from 'react';
import TooltipMUI from '@mui/material/Tooltip';
import CircleNotificationsSharpIcon from '@mui/icons-material/CircleNotificationsSharp';
import Zoom from '@mui/material/Zoom';

/**
 * If props.children are passed to Tooltip, then no icon is shown.  If no children are passed, then an icon is displayed.
 * @param {string} title
 * @returns tooltip bubble
 */
export default function Tooltip(props) {
	let placement = 'top';
	if (props.placement) {
		placement = props.placement;
	}
	return (
		<TooltipMUI
			title={props.title}
			placement={placement}
			arrow
			TransitionComponent={Zoom}
			enterDelay={200}
			leaveDelay={100}
		>
			{props.children ? props.children : <CircleNotificationsSharpIcon fontSize='small' />}
		</TooltipMUI>
	);
}
