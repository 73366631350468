import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import Grid from '@mui/material/Unstable_Grid2';

import PageCards from '../components/ui/PageCards';
import HelpCard from '../components/layout/HelpCard';
import List from '../components/list/List';
import FacilityInfo from '../components/facilityInfo/FacilityInfo';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import parse from 'html-react-parser';
import parserOptions from '../components/ui/ParserOptions';

function SPAHPage(props) {
	const [pageInfo, setPageInfo] = useState('');
	const [loading, setLoading] = useState(false);

	//gets the page info
	useEffect(() => {
		const getFacilityPageInfo = async () => {
			setLoading(true);
			const docRef = doc(props.db, 'settings', 'SPAH');
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				setPageInfo(docSnap.data().pageInfo);
			} else {
				setPageInfo('');
			}

			setLoading(false);
		};

		getFacilityPageInfo();
	}, [props.db]);

	if (loading) {
		return <LoadingSpinner />;
	}

	return (
		<Grid container spacing={2}>
			<Grid xs={12} md={4} ml={'5px'}>
				<PageCards>
					<List facility='spah' />
				</PageCards>
			</Grid>
			<Grid xs={12} md={3.9}>
				<PageCards>
					<section>
						<h1>SPAH</h1>
						{parse(pageInfo, parserOptions)}
					</section>
				</PageCards>
				<PageCards>
					<HelpCard title='SPAH' />
				</PageCards>
			</Grid>
			<Grid xs={12} md={4} mr={'5px'}>
				<FacilityInfo facility='SPAH' />
			</Grid>
		</Grid>
	);
}

export default SPAHPage;
