import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import PageCards from '../components/ui/PageCards';

import classes from './Pages.module.css';

function HomePage() {
	return (
		<Grid container spacing={2}>
			<Grid xs={12} md={4}></Grid>
			<Grid xs={12} md={4}>
				<PageCards>
					<section>
						<h1>EPT Admission Lists</h1>
						<p className={classes.center}>Choose a Facility at the top to view their Admission List.</p>
					</section>
				</PageCards>
			</Grid>
			<Grid xs={12} md={4}></Grid>
		</Grid>
	);
}

export default HomePage;
