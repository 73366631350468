import { domToReact } from 'html-react-parser';

const parserOptions = {
	replace: ({ attribs, children }) => {
		if (!attribs) {
			return;
		}

		//indents 1st li
		if (attribs.class === 'ql-indent-1') {
			return (
				<ul>
					<li>{domToReact(children, parserOptions)}</li>
				</ul>
			);
		}

		//indents 2nd li
		if (attribs.class === 'ql-indent-2') {
			return (
				<ul>
					<ul>
						<li>{domToReact(children, parserOptions)}</li>
					</ul>
				</ul>
			);
		}

		//aligns items centered
		if (attribs.class === 'ql-align-center') {
			return <div style={{ textAlign: 'center' }}>{domToReact(children, parserOptions)}</div>;
		}

		//aligns items right
		if (attribs.class === 'ql-align-right') {
			return <div style={{ textAlign: 'right' }}>{domToReact(children, parserOptions)}</div>;
		}

		//aligns items left
		if (attribs.class === 'ql-align-justify') {
			return <div style={{ textAlign: 'justify' }}>{domToReact(children, parserOptions)}</div>;
		}
	},
};

export default parserOptions;
