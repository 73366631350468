import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import PageCards from '../components/ui/PageCards';
import { IconButton, TextField } from '@mui/material';
import AlertContext from '../components/ui/AlertContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import { db } from '../App';

const Admin_Edit_Categories = () => {
	const alertCtx = useContext(AlertContext);
	const [loading, setLoading] = useState(false);
	const [createLoading, setCreateLoading] = useState(false);
	const [category, setCategory] = useState('');
	const [categoryList, setCategoryList] = useState([]);

	useEffect(() => {
		const getCategories = async () => {
			setLoading(true);
			const docRef = doc(db, 'settings', 'Settings');
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				const tmpArr = docSnap.data().Categories_For_On_Shift_Info;
				setCategoryList(tmpArr.sort());
			} else {
				setCategoryList([]);
			}
			setLoading(false);
		};
		getCategories();
	}, []);

	const onChangeCategory = (e) => {
		setCategory(e.target.value);
	};

	const handleAddCategories = async () => {
		if (category === '') return;
		const exists = categoryList.findIndex((item) => item.toLowerCase() === category.toLowerCase());
		if (exists >= 0) {
			alertCtx.setSeverity('error');
			alertCtx.setTitle('The Category already exists');
			alertCtx.setActive(true);
			return;
		}
		setCreateLoading(true);
		try {
			const docRef = doc(db, 'settings', 'Settings');
			await updateDoc(docRef, {
				Categories_For_On_Shift_Info: arrayUnion(category),
			});
			const tmpArr = [...categoryList, category];
			setCategoryList(tmpArr.sort());
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Category added successfully');
			alertCtx.setActive(true);
			alertCtx.setTimer(5000);
			setCreateLoading(false);
			setCategory('');
		} catch (error) {
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error adding category');
			alertCtx.setActive(true);
			setCreateLoading(false);
		}
	};

	const handleDelete = async (item) => {
		try {
			const docRef = doc(db, 'settings', 'Settings');
			await updateDoc(docRef, {
				Categories_For_On_Shift_Info: arrayRemove(item),
			});
			const facilityesSnapshot = await getDocs(collection(db, 'FacilityInfo'));
			const updatePromises = [];
			facilityesSnapshot.forEach(async (facilityDoc) => {
				const facilityData = facilityDoc.data();
				const tmpCategories = facilityData.Categories || [];
				const updatedCategories = tmpCategories.filter((category) => category.toLowerCase() !== item.toLowerCase());
				if (updatedCategories.length !== tmpCategories.length) {
					const facilityRef = doc(db, 'FacilityInfo', facilityDoc.id);
					updatePromises.push(updateDoc(facilityRef, { Categories: updatedCategories }));
				}
			});
			await Promise.all(updatePromises);
			const updatedCategoryList = categoryList.filter((category) => category !== item);
			setCategoryList(updatedCategoryList);
			alertCtx.setSeverity('success');
			alertCtx.setTitle('Category deleted successfully');
			alertCtx.setActive(true);
		} catch (error) {
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Failed to delete category');
			alertCtx.setActive(true);
		}
	};

	return (
		<Grid container spacing={1} m={2}>
			<Grid xs={12}>
				<PageCards>
					<h1>Categories Management</h1>
					<h3>Add/Delete categories involved with On Shift Info</h3>
				</PageCards>
			</Grid>
			<Grid xs={12}>
				<PageCards>
					<Box maxWidth={700} display='flex' flexDirection='row' justifyContent='space-between' gap={2}>
						<TextField
							type='text'
							variant='outlined'
							value={category}
							fullWidth
							margin='dense'
							placeholder='Category Name'
							inputProps={{
								style: {
									padding: 10,
								},
							}}
							onChange={onChangeCategory}
						/>
						<LoadingButton
							variant='contained'
							onClick={handleAddCategories}
							size='small'
							loading={createLoading}
							sx={{
								height: '44px',
								margin: 'auto',
							}}
						>
							Add
						</LoadingButton>
					</Box>
					{loading ? (
						<Box py={6}>
							<LoadingSpinner />
						</Box>
					) : (
						<Box maxWidth={700}>
							{!loading &&
								categoryList.length > 0 &&
								categoryList.map((item, index) => (
									<Box
										key={index}
										display='flex'
										flexDirection='row'
										justifyContent='space-between'
										marginTop='2px'
										marginBottom='2px'
									>
										<span style={{ width: '100%', padding: '10px', borderRadius: '3px', background: '#edf2f9' }}>
											{item}
										</span>
										<IconButton sx={{ color: '#d74545' }} onClick={() => handleDelete(item)}>
											<DeleteIcon />
										</IconButton>
									</Box>
								))}
							{!loading && categoryList.length <= 0 && (
								<Box textAlign={'center'} color={'red'} marginTop={2}>
									No categories
								</Box>
							)}
						</Box>
					)}
				</PageCards>
			</Grid>
		</Grid>
	);
};

export default Admin_Edit_Categories;
