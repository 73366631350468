import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, Divider } from '@mui/material';

import './FacilityMenu.css';

export default function FacilityMenu() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Fragment>
			<button onClick={handleClick} className='menu-trigger'>
				Facilities
			</button>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 28,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem component={Link} to='/sblh'>
					SBLH
				</MenuItem>
				<MenuItem component={Link} to='/si'>
					SI
				</MenuItem>
				<MenuItem component={Link} to='/slh'>
					SLH
				</MenuItem>
				<MenuItem component={Link} to='/sngh'>
					SNGH
				</MenuItem>
				<MenuItem component={Link} to='/soh'>
					SOH
				</MenuItem>
				<MenuItem component={Link} to='/spah'>
					SPAH
				</MenuItem>
				<MenuItem component={Link} to='/svbgh'>
					SVBGH
				</MenuItem>
			</Menu>
		</Fragment>
	);
}
