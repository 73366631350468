import React, { useState, useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';

import PageCards from '../ui/PageCards';
import FacilityInfoCard from './FacilityInfoCard';
import classes from './FacilityInfo.module.css';
import LoadingSpinner from '../ui/LoadingSpinner';
import { Box, Tab, Tabs, TextField } from '@mui/material';
import { db } from '../../App';

export default function FacilityInfo(props) {
	const [isLoading, setIsLoading] = useState(true);
	const [loadedList, setLoadedList] = useState([]);
	const [searchedList, setSearchedList] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [categoryList, setCategoryList] = useState([]);
	const [searchedCategoryList, setSearchedCategoryList] = useState([]);
	const [activeTab, setActiveTab] = useState('all');
	const [facility, setFacility] = useState(props.facility);

	// gets the facility list of info cards, sorts them by date and then alphabetically
	useEffect(() => {
		let isMounted = true;

		const getFacilityInfo = async () => {
			setIsLoading(true);

			const facilityRef = collection(db, 'FacilityInfo');
			let q;
			if (props.facility === 'ALL') q = query(facilityRef);
			else q = query(facilityRef, where('Facilities', 'array-contains', props.facility));
			const querySnapshot = await getDocs(q);

			const facilityList = [];
			querySnapshot.forEach((doc) => {
				facilityList.push({ ...doc.data(), id: doc.id });
			});
			
			function compare(a, b) {
				if (a.Title < b.Title) {
					return -1;
				}
				if (a.Title > b.Title) {
					return 1;
				}
				return 0;
			}

			const NOW = new Date().getTime();

			let newItems = [];
			let updatedItems = [];
			let oldItems = [];

			for (let i = 0; i < facilityList.length; i++) {
				const item = facilityList[i];
				const itemDate = item.Date.toDate().getTime();
				const itemUpdatedDate = item.UpdatedDate ? item.UpdatedDate.toDate().getTime() : null;

				if (itemDate + 1000 * 60 * 60 * 24 * 14 > NOW) {
					newItems.push(item);
				} else if (itemUpdatedDate && itemUpdatedDate + 1000 * 60 * 60 * 24 * 14 > NOW) {
					updatedItems.push(item);
				} else {
					oldItems.push(item);
				}
			}

			newItems.sort(compare);
			updatedItems.sort(compare);
			oldItems.sort(compare);

			const newList = newItems.concat(updatedItems).concat(oldItems);

			if (isMounted) {
				setLoadedList(newList);
				setIsLoading(false);
			}
		};
		getFacilityInfo();
		return () => {
			isMounted = false;
		};
	}, [props.addedItem, props.deletedItem, props.facility]);

	useEffect(() => {
		if (props.categoryList) {
			setCategoryList(props.categoryList);
		} else {
			const getCategories = async() => {
				const categoryRef = doc(db, 'settings', 'Settings');
				const docSnap = await getDoc(categoryRef);
				if(docSnap.exists()) {
					setCategoryList(JSON.parse(JSON.stringify(docSnap.data().Categories_For_On_Shift_Info)));
				} else {
					setCategoryList([]);
				}
			}
			getCategories();
		}
	},[props]);

	// // renames SSU facilities to display name correctly
	// useEffect(() => {
	// 	if (props.facility === 'SSU_SOH') {
	// 		setFacility('SOH SSU');
	// 	}
	// }, [props.facility]);

	useEffect(() => {
		let tmpCategories = JSON.parse(JSON.stringify(categoryList));
		tmpCategories = tmpCategories.sort();
		let tmpList = JSON.parse(JSON.stringify(loadedList));
		if(searchText) {
			const searchTextArr = cleanSearchText(searchText);
			let i=0;
			tmpCategories = categoryList.filter((item) => {
				for (i = 0; i < searchTextArr.length; i ++) {
					if ( !item.toLowerCase().includes(searchTextArr[i]) ) break;
				}
				if(i < searchTextArr.length) return false;
				return true;
			});
			tmpList = loadedList.filter((item) => {
				for (i=0; i< searchTextArr.length; i ++ ) {
					if(!item.Title?.toLowerCase().includes(searchTextArr[i]) && !item.Summary?.toLowerCase().includes(searchTextArr[i]) && !item.Main?.toLowerCase().includes(searchTextArr[i])) break;
				}
				if(i < searchTextArr.length) return false;
				return true;
			})
		}
		if (tmpCategories.indexOf(activeTab) > -1) {
			tmpList = tmpList.filter((item) => {
				// console.log(item);
				if(item.Categories && item.Categories.indexOf(activeTab) > -1) return true;
				return false;
			})
			setSearchedList(tmpList);
		} else {
			if (activeTab !== 'others') {
				setActiveTab('all');
				setSearchedList(tmpList);
			} else {
				tmpList = tmpList.filter((item) => {
					if (!item.Categories?.length) return true;
					return false;
				})
				setSearchedList(tmpList);
			}
		}
		setSearchedCategoryList(tmpCategories);
	},[searchText, categoryList, loadedList, activeTab]);

	const cleanSearchText = (searchText) => {
		const words = searchText.split(" ");
		const cleanedWords = words.map((word) => {
			return word.replace(/[^\w\s]/gi, '').trim().toLowerCase();
		});
		return cleanedWords;
	}

	const handleSearch = async (search='') => {
		const categoryRef = doc(db, 'settings', 'Settings');
		const docSnap = await getDoc(categoryRef);
		let tmpCategories = [];
		if(docSnap.exists()) {
			tmpCategories = docSnap.data().Categories_For_On_Shift_Info;
		}
		if (search) {
			tmpCategories = tmpCategories.filter((item) => item.includes(search));
			if(!tmpCategories.length) setActiveTab('all');
		}
		setCategoryList(tmpCategories);
	}

	const handleSearchChange = (e) => {
		const search = e.target.value;
		setSearchText(search);
		handleSearch(search);
	}

	const handleTabChange = (e, val) => {
		setActiveTab(val);
	}
	
	// if delete button selected on FacilityInfoCard, then continue to pass card contents up to Admin
	const handleDeleteEntry = (obj) => {
		props.selectDeleteEntry(obj);
	};

	// if edit button selected on FacilityInfoCard, then continue to pass card contents up to Admin
	const handleEditEntry = (obj) => {
		props.selectEditEntry(obj);
	};

	if (isLoading) {
		return <LoadingSpinner />;
	}
	return (
		<React.Fragment>
			<PageCards>
				{props.page !== 'Admin' && (
					<Box>
						<h3>On Shift Info</h3>
						<section>
							Use the information in the card(s) below when on shift at{' '}
							<span className={classes.facilityName}>{facility}</span>. Click a card to expand a section of interest.
						</section>
					</Box>
				)}
				<Box mt={2}>
					<TextField
						onChange={handleSearchChange}
						value={searchText}
						fullWidth
						placeholder='Search Info Cards'
						inputProps={{
							style: {
								padding: 10
							}
						}}
						type='search'
					/>
				</Box>
				<Box mt={2}>
					<Tabs
						onChange={handleTabChange}
						variant='scrollable'
						scrollButtons
						allowScrollButtonsMobile
						value={activeTab}
					>
						<Tab label="ALL" value='all' />
						{
							searchedCategoryList.map((item, index) => (
								<Tab label={item} key={index} value={item} />
							))
						}
						<Tab label="Others" value="others" />
					</Tabs>
				</Box>
				<Box mt={2} mr={1} ml={1} p={1} className={classes.scrollableSection}>
					{!searchedList.length && <Box textAlign='center'>No cards</Box>}
					{searchedList.length > 0 && searchedList.map((facilityInfo, index) => (
						<FacilityInfoCard
							key={index}
							info={facilityInfo}
							selectEditEntry={handleEditEntry}
							selectDeleteEntry={handleDeleteEntry}
							facility={props.facility}
							page={props.page}
						/>
					))}
				</Box>
			</PageCards>
		</React.Fragment>
	);
}
