import React, { useEffect, useState, useRef } from 'react';
import { getStorage, ref, deleteObject } from 'firebase/storage';

import classes from './FileUploads.module.css';
import fileUploadImage from '../images/icons8-add-file-64.png';
import { Chip, Stack } from '@mui/material';

export default function FileUploads(props) {
	const inputFile = useRef(null);
	const storage = getStorage();
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [initialLoaded, setInitialLoaded] = useState(false);
	const [wrongFileType, setWrongFileType] = useState(false);
	const [fileExists, setFileExists] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState(props.initialFiles);

	useEffect(() => {
		if (selectedFiles.length === 0) setSelectedFiles(props.initialFiles);
	}, [props.initialFiles]);

	//if editing an entry, props.initialFiles will not be null, so setUploadedFiles state to that
	useEffect(() => {
		if (props.initialFiles !== null && uploadedFiles.length === 0 && !initialLoaded) {
			const fileArray = [];

			//for all items, set each to a file
			for (const key in props.initialFiles) {
				const file = {
					key: key,
					...props.initialFiles[key],
				};
				fileArray.push(file);
			}
			//set array to state
			setUploadedFiles(fileArray);
		}
	}, [props.initialFiles, uploadedFiles.length, initialLoaded]);

	//set initialLoaded to true so that FileUploads doesn't keep reloading props.initialFiles
	useEffect(() => {
		if (uploadedFiles.length > 0) {
			setInitialLoaded(true);
		}
	}, [uploadedFiles.length]);

	//when user clicks on fileUploadImage
	const onFileClick = () => {
		inputFile.current.click();
	};

	//handles elevating the selectedFiles to FacilityInfoForm
	useEffect(() => {
		props.fileChanges(selectedFiles);
	}, [selectedFiles]);

	//handles onChange event for selecting a file or files
	const handleFileChange = (event) => {
		let files = Array.from(event.target.files);

		// Filter files to keep only PDFs or images
		const validFiles = files.filter((file) => file.type === 'application/pdf' || file.type.includes('image/'));

		// Check if there were any invalid files
		if (validFiles.length !== files.length) {
			setWrongFileType(true);
			setTimeout(() => {
				setWrongFileType(false);
			}, 3000);
		}

		setSelectedFiles((prevState) => {
			let finalState;
			if (prevState?.length > 0) finalState = [...prevState, ...validFiles];
			else finalState = validFiles;
			return finalState;
		});
	};

	// Function to handle removing a file
	const handleRemoveItem = (indexToRemove) => {
		if (!selectedFiles) return;

		if (selectedFiles[indexToRemove].url) deleteItem(selectedFiles[indexToRemove]);

		const filteredFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
		setSelectedFiles(filteredFiles);
	};

	//deletes an item, passed as a prop to the FileUploaded function
	const deleteItem = (file) => {
		// Create a reference to the file to delete
		const deleteRef = ref(storage, file.reference);

		// Delete the file
		deleteObject(deleteRef).catch((err) => console.error(err));
	};

	// opens url
	const handleClick = (url) => {
		if (url) {
			window.open(url, '_blank');
		}
	};

	return (
		<React.Fragment>
			<div className={classes.fileUpload}>
				<label>File Upload</label> <div className={classes.spacer} />{' '}
				{!fileExists && wrongFileType && (
					<span className={classes.error}>Only PDFs and Images are allowed to be uploaded.</span>
				)}
				{!fileExists && !wrongFileType && <img src={fileUploadImage} onClick={onFileClick} alt='upload' />}
				{!fileExists && !wrongFileType && (
					<input type='file' ref={inputFile} multiple onChange={handleFileChange} style={{ display: 'none' }} />
				)}
			</div>
			{/*display the list of files uploaded*/}
			<Stack m={2} spacing={1}>
				{selectedFiles &&
					selectedFiles.map((file, index) => (
						<Chip
							key={index}
							label={file.name}
							reference={file.reference}
							url={file.url}
							type={file.type}
							color={file.url ? 'info' : 'primary'}
							variant='outlined'
							onDelete={() => handleRemoveItem(index)}
							onClick={() => handleClick(file.url)}
						/>
					))}
			</Stack>
		</React.Fragment>
	);
}
