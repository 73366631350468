import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

import SignIn from '../auth/SignIn';
import AuthContext from '../auth/authContext';
import AlertContext from '../ui/AlertContext';
import classes from './MainNavigation.module.css';
import logo from '../images/EPT Logo Transparent.png';
import AvatarMenu from '../ui/AvatarMenu';
import FacilityMenu from '../ui/FacilityMenu';

function MainNavigation() {
	const authCtx = useContext(AuthContext);
	const alertCtx = useContext(AlertContext);
	const isLoggedIn = authCtx.isLoggedIn;
	const isAdmin = authCtx.isAdmin;
	const photoURL = authCtx.photo;
	const userName = authCtx.userName;
	const email = authCtx.email;

	return (
		<header className={classes.header}>
			<div className={classes.containerLogo}>
				<Link to='/'>
					<img className={classes.logo} src={logo} alt='EPT Logo' />
				</Link>
			</div>

			<div className={classes.containerAlert}>
				{alertCtx.active && (
					<Alert
						severity={alertCtx.severity}
						onClose={() => {
							alertCtx.setActive(false);
						}}
					>
						<AlertTitle>{alertCtx.title}</AlertTitle>
						{/* used dangerouslySetInnerHTML as all html messages are pre-set and safe and this allows for html content to be rendered */}
						<div dangerouslySetInnerHTML={{ __html: alertCtx.message }} />
					</Alert>
				)}
			</div>
			<div className={classes.containerLogin}>
				<Stack direction='row' spacing={2}>
					<FacilityMenu />
					<Grid sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
						{!isLoggedIn && <SignIn />}
						{isLoggedIn && <AvatarMenu isAdmin={isAdmin} photoURL={photoURL} userName={userName} email={email} />}
					</Grid>
				</Stack>
			</div>
		</header>
	);
}

export default MainNavigation;
