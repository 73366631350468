import React from 'react';
import Modal from '../ui/Modal';

import classes from './DeleteModal.module.css';

export default function DeleteCardModal(props) {
	const handleCancel = () => {
		props.onClose();
	};

	const handleDelete = () => {
		props.onDelete(props.objectToDelete);
	};

	return (
		<Modal onClose={handleCancel}>
			<div className={classes.statement}>
				Do you want to delete: <span className={classes.title}>{props.title}</span>
			</div>
			<br></br>
			<div className={classes.statement}>This action cannot be undone.</div>
			<br></br>
			<div className={classes.center}>
				<button className={classes.cancelButton} onClick={handleCancel}>
					Cancel
				</button>
				<button className={classes.deleteButton} onClick={handleDelete}>
					Delete
				</button>
			</div>
		</Modal>
	);
}
