import React from 'react';
import pdfIcon from '../images/icons8-pdf-40.png';
import imgIcon from '../images/icons8-xlarge-icons-40.png';
import classes from './FileCard.module.css';

export default function FileCard(props) {
	//determines icon based on file type
	let uploadIcon;
	if (props.type === 'application/pdf') {
		uploadIcon = pdfIcon;
	} else {
		uploadIcon = imgIcon;
	}

	return (
		<div className={classes.fileContainer}>
			<a href={props.url} target='_blank' rel='noreferrer'>
				<div className={classes.innerContainer}>
					<div className={classes.uploadIcon}>
						<img src={uploadIcon} alt='upload icon' />
					</div>
					<div className={classes.fileName}>{props.name}</div>
				</div>
			</a>
		</div>
	);
}
